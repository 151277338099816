import React, {useState} from "react";
// import {NavLink} from "react-router-dom";
// Chakra imports
import {
    Box,
    Button,
    // Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue, useDisclosure,
    useToast,
    Image
} from "@chakra-ui/react";
// Custom components
// import DefaultAuth from "layouts/auth/Default";
import SignUpModal from "../../../components/signup/signup";

// Assets
// import illustration from "assets/img/auth/sadp-cream.png";
import {MdOutlineRemoveRedEye} from "react-icons/md";
import {RiEyeCloseLine} from "react-icons/ri";
import axios from "axios";
import Footer from "../../../components/footer/FooterAdmin";

function SignIn() {
    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    // const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    // const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const {isOpen, onClose} = useDisclosure();

    // const navigate = useNavigate();

    const handleLogin = async () => {
        setIsLoading(true);
        try {
            // Replace with your actual API endpoint
            const response = await axios.post(
                process.env.REACT_APP_API_URL + '/v1/login',
                {
                    email,
                    password,
                }
            );

            const token = response.data.data.token;
            const user = response.data.data.name;

// Save token to cookies
            document.cookie = `token=${token}; path=/`;

// Save user data to cookies
            document.cookie = `user=${JSON.stringify(user)}; path=/`;

            if (token && user) {
                toast({
                    title: "Login successful",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });

                // Navigate to admin dashboard
                window.location.href = "/admin";
            } else {
                if (response.data.code === 200) {

                    toast({
                        title: "Login failed , Call your IT Team",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                    });
                }
            }


        } catch (error) {
            toast({
                title: "Login failed",
                description: error.response?.data?.message || "An error occurred. Please try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Flex
            maxW={{base: "100%", md: "max-content"}}
            w='100%'
            mx="auto"
            h='100vh'
            alignItems='center'
            justifyContent='center'
            px={{base: "25px", md: "0px"}}
            flexDirection='column'
            bgGradient="linear(white)">
            <Flex
                zIndex='2'
                direction='column'
                w={{base: "100%", md: "420px"}}
                maxW='100%'
                bg="white"
                boxShadow="xl"
                borderRadius='20px'
                mx="auto"
                my="auto"
                p="40px">
                <Box mb='24px'/>
                <Box display="flex" justifyContent="center">
                    <img
                        src={`${process.env.PUBLIC_URL}/logosinaralam.png`}
                        alt="Logo Sinar Alam"
                        style={{maxWidth: "200px", height: "auto"}}
                    />
                </Box>
                <Box mb='40px'/>
                <FormControl>
                    <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='600'
                        color={textColor}
                        mb='8px'>
                        Email<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                        isRequired={true}
                        variant='auth'
                        fontSize='sm'
                        ms={{base: "0px", md: "0px"}}
                        type='email'
                        placeholder='Enter your email'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        _focus={{borderColor: "blue.400", boxShadow: "0 0 0 1px blue.400"}}
                    />
                    <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='600'
                        color={textColor}
                        display='flex'>
                        Password<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size='md'>
                        <Input
                            isRequired={true}
                            fontSize='sm'
                            placeholder='Enter your password'
                            mb='24px'
                            size='lg'
                            type={show ? "text" : "password"}
                            variant='auth'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            _focus={{borderColor: "blue.400", boxShadow: "0 0 0 1px blue.400"}}
                        />
                        <InputRightElement display='flex' alignItems='center' mt='4px'>
                            <Icon
                                color={textColorSecondary}
                                _hover={{cursor: "pointer", color: "blue.500"}}
                                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                onClick={handleClick}
                            />
                        </InputRightElement>
                    </InputGroup>
                    <Button
                        fontSize='sm'
                        variant='brand'
                        fontWeight='600'
                        w='100%'
                        h='50'
                        mb='24px'
                        type="submit"
                        isLoading={isLoading}
                        onClick={handleLogin}
                        bgGradient="linear(to-r, blue.400, blue.600)"
                        _hover={{
                            bgGradient: "linear(to-r, blue.500, blue.700)",
                            transform: "translateY(-2px)",
                            transition: "all 0.2s"
                        }}>
                        Sign In
                    </Button>
                </FormControl>
                <Flex
                    flexDirection='column'
                    justifyContent='center'
                    maxW='100%'
                    mt='0px'>
                    <SignUpModal isOpen={isOpen} onClose={onClose}/>
                </Flex>
            </Flex>
            <Flex
                zIndex='2'
                direction='column'
                w={{base: "100%", md: "auto"}}
                maxW='100%'
                bg="white"
                boxShadow="xl"
                borderRadius='20px'
                mx="auto"
                my="auto">
                <Box mb='24px'/>
                <Box
                    position="relative"
                    width="100%"
                    overflow="hidden"
                >
                    <Box
                        display="flex"
                        animation="slideLoop 60s linear infinite"
                        sx={{
                            '@keyframes slideLoop': {
                                '0%': {transform: 'translateX(0)'},
                                '100%': {transform: 'translateX(-50%)'}
                            },
                            '&:hover': {
                                animationPlayState: 'paused'
                            },
                            transition: 'all 0.5s ease-in-out',
                        }}
                    >
                        {/* Complete first set */}
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/CTUM.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/DBT.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/DML.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/DMLD.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/DPL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/gmkl_HD.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/KPL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/MJL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/SADP.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/SADP2.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/SAL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/SAP.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/SMPT.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/TSL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/BPR.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/CBAL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/CBML.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/CMPLSSML.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/CPEL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/CPKA.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />

                        {/* Duplicate set */}
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/CTUM.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/DBT.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/DML.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/DMLD.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/DPL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/gmkl_HD.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/KPL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/MJL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/SADP.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/SADP2.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/SAL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/SAP.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/SMPT.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                        <Image
                            src={`${process.env.PUBLIC_URL}/LogoAll/TSL.png`}
                            alt="Logo Sinar Alam"
                            maxW="80px"
                            h="auto"
                            mx="20px"
                            fallbackSrc="https://via.placeholder.com/80"
                        />
                    </Box>
                </Box>
                <Box mb='40px'/>
            </Flex>
            <Box mb='10px'/>
            <Footer/>

        </Flex>
    )
        ;
}

export default SignIn;
