import React from 'react';
import { Button, Heading, Text, Container, VStack } from "@chakra-ui/react";
import { useLocation } from 'react-router-dom';

const VerifyEmail = () => {
  const location = useLocation();

  const handleVerify = () => {
    // Get token from URL search params
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    // Redirect to the verification URL with token
    window.location.href = `${process.env.REACT_APP_API_URL}/v1/activate?token=${token}`;
  };

  return (
    <Container maxW="container.sm" py={10}>
      <VStack spacing={6} align="center" bg="white" p={8} borderRadius="lg" boxShadow="lg">
        <Heading as="h1" size="xl" color="blue.600">
          Email Verification
        </Heading>
        <Text fontSize="lg" textAlign="center" color="gray.600">
          Please click the button below to verify your email address
        </Text>
        <Button
          colorScheme="blue"
          size="lg"
          onClick={handleVerify}
          width="200px"
          _hover={{ transform: 'scale(1.05)' }}
          transition="all 0.2s"
        >
          Verify Email
        </Button>
      </VStack>
    </Container>
  );
};

export default VerifyEmail;