export const columnsDataRole = [
  {
    accessorKey: "roleName",
    header: "Name Role",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "description",
    header: "Description",
    cell: (info) => info.getValue(),
  },
];
