import {Portal, Box, useDisclosure, Image, Text} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
import Navbar from 'components/navbar/NavbarAdmin.js';
import React, {useState} from 'react';
import {jwtDecode} from 'jwt-decode';

// Custom Chakra theme
export default function Dashboard(props) {
    const {...rest} = props;
    const [fixed] = useState(false);
    const isDevelopment = process.env.REACT_APP_ENV === 'development';
    const apiHost = process.env.REACT_APP_API_HOST;

    const getAuthUrl = () => {
        if (isDevelopment) {
            return `${apiHost}`;
        }
        return `${process.env.REACT_APP_BL}`;
    };

    // Function to get a cookie value
    function getCookieValue(name) {
        const cookieString = document.cookie
            .split('; ')
            .find((row) => row.startsWith(`${name}=`));
        return cookieString ? cookieString.split('=')[1] : null;
    }

    const token = getCookieValue('token');

    // Decode token if it exists
    const decoded = token ? jwtDecode(token) : null;

    if (decoded) {
        console.log(decoded); // Only logs if the token is decoded successfully
    } else {
        console.warn('Token is missing or invalid.');
    }

    // Show Terra Tangki section based on decoded roles
    const showTeraTangki = decoded?.roles?.includes('tera:web:tbbmkuin:view');
    const showAuth = decoded?.roles?.includes('auth:web:master:view');
    const showWarehouse = decoded?.roles?.includes('warehouse:web:master:view');
    const showDashboard = decoded?.roles?.includes('dashboard:web:master:view');
    const showGeneratedNRP = decoded?.roles?.includes('generated-nrp:web:master:view');
    const showBlacklist = decoded?.roles?.includes('blacklist:data:data-blacklist:view');

    // Chakra UI Portal and disclosure
    const {onOpen} = useDisclosure();

    return (
        <Box
            float="center"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
        >
            <Portal>
                <Box>
                    <Navbar onOpen={onOpen} fixed={fixed} {...rest} />
                </Box>
            </Portal>

            <Box
                mx="auto"
                p={{base: '20px', md: '30px'}}
                pe="20px"
                minH="100vh"
                pt="120px" // Increased padding-top here to move the menu boxes lower
                mt="20px" // Optional: Add margin-top to create additional space
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={{base: 'column', sm: 'row'}} // Stacked by default, row for sm and above
                flexWrap="wrap"
            >
                {/* User Roles */}
                {showAuth && (
                    <Box display="flex" flexDirection="column" alignItems="center" mx="10px" boxShadow="lg">
                        <a href={`${process.env.REACT_APP_API_HOST}:3000/master`}>
                            <Image
                                src={require('assets/img/user.png')}
                                alt="User Roles"
                                height={250}
                                width={250}
                                mb="10px"
                            />
                        </a>
                        <Text>
                            User Roles
                        </Text>
                    </Box>
                )}

                {/* Warehouse */}
                {showWarehouse && (
                    <Box display="flex" flexDirection="column" alignItems="center" mx="10px" boxShadow="lg">
                        <a href={`${process.env.REACT_APP_API_HOST}:3001`}>
                            <Image
                                src={require('assets/img/warehouse.png')}
                                alt="Warehouse"
                                height={250}
                                width={250}
                                mb="10px"
                            />
                        </a>
                        <Text>
                            Warehouse
                        </Text>
                    </Box>
                )}

                {/* Dashboard */}
                {showDashboard && (
                    <Box display="flex" flexDirection="column" alignItems="center" mx="10px" boxShadow="lg">
                        <a href={`${process.env.REACT_APP_API_HOST}:3002`}>
                            <Image
                                src={require('assets/img/dashboard.png')}
                                alt="Dashboard"
                                height={250}
                                width={250}
                                mb="10px"
                            />
                        </a>
                        <Text>
                            Dashboard
                        </Text>
                    </Box>
                )}

                {/* Terra Tangki (only show if the user has the correct role) */}
                {showTeraTangki && (
                    <Box display="flex" flexDirection="column" alignItems="center" mx="10px" boxShadow="lg">
                        <a href={`${process.env.REACT_APP_API_HOST}:3003`}>
                            <Image
                                src={require('assets/img/TeraTangki.png')}
                                alt="Terra System"
                                height={250}
                                width={250}
                                mb="10px"
                            />
                        </a>
                        <Text>Terra System</Text>
                    </Box>
                )}

                {showBlacklist && (
                    <Box display="flex" flexDirection="column" alignItems="center" mx="10px" boxShadow="lg">
                        <a href={getAuthUrl()}>
                            <Image
                                src={require('assets/img/blacklist.png')}
                                alt="Blacklist System"
                                height={250}
                                width={250}
                                mb="10px"
                            />
                        </a>
                        <Text>Blacklist System</Text>
                    </Box>
                )}



                {showGeneratedNRP && (
                    <Box display="flex" flexDirection="column" alignItems="center" mx="10px" boxShadow="lg">
                        <a href={`http://ops.sinaralam.com/akerp/content/erp/id.php`}>
                            <Image
                                src={require('assets/img/generatenrp.png')}
                                alt="Generate NRP"
                                height={250}
                                width={250}
                                mb="10px"
                            />
                        </a>
                        <Text>
                            Generate NRP
                        </Text>
                    </Box>
                )}

            </Box>

            {/* Footer */}
            <Box>
                <Footer/>
            </Box>
        </Box>
    );
}
