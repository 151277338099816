import React, {useEffect, useState} from "react";
import {Box, Button, Input, useColorModeValue} from "@chakra-ui/react";
import ColumnsTable from "./components/ColumnsTable";
import {columnsDataColumns} from "./variables/columnsData";

export default function Settings({onDataUpdate}) {
    const [tableData, setTableData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        name: "",
        email: "",
        nrp: ""
    });

    const inputBgColor = useColorModeValue("white", "gray.700");
    const inputBorderColor = useColorModeValue("gray.200", "gray.600");


    // Fetch data from API with pagination and filters
    useEffect(() => {

        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];



        // console.log(token)
        fetch(
            process.env.REACT_APP_API_URL + `/v1/users?page=${pageIndex + 1}&size=${pageSize}&name=${filters.name}&nrp=${filters.nrp}&email=${filters.email}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    // console.log(data.data)
                    setTableData(data.data.content);
                    setTotalPages(data.data.totalPages);
                } else {
                    console.error("Failed to fetch users data");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [pageIndex, pageSize, filters]); // Update fetch when pageIndex, pageSize, or filters change


    const handleDataUpdate = () => {
        // Fetch data terbaru

        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        // console.log(token)
        fetch(
            process.env.REACT_APP_API_URL + `/v1/users?page=${pageIndex + 1}&size=${pageSize}&name=${filters.name}&nrp=${filters.nrp}&email=${filters.email}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setTableData(data.data.content);
                    setTotalPages(data.data.totalPages);
                } else {
                    console.error("Failed to fetch users data");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    // Handle filter changes
    const handleFilterChange = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value});
    };

    return (
        <Box pt={{base: "130px", md: "80px", xl: "80px"}}>
            {/* Add Button */}
            {/*<Box display="flex" justifyContent="flex-end" mb="1.5rem">*/}
            {/*    <Button*/}
            {/*        colorScheme="brand"*/}
            {/*        size="sm"*/}
            {/*        leftIcon={<FaPlus/>}*/}
            {/*        onClick={() => {*/}
            {/*            console.log("button add clicked");*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Tambah Data*/}
            {/*    </Button>*/}
            {/*</Box>*/}
            {/* Filter Inputs */}
            <Box display="flex" gap="1rem" mb="1.5rem">
                <Input
                    placeholder="Filter by Name"
                    name="name"
                    value={filters.name}
                    onChange={handleFilterChange}
                    bg={inputBgColor}
                    borderColor={inputBorderColor}
                    _hover={{borderColor: "gray.300"}}
                    _focus={{borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce"}}
                />
                <Input
                    placeholder="Filter by Email"
                    name="email"
                    value={filters.email}
                    onChange={handleFilterChange}
                    bg={inputBgColor}
                    borderColor={inputBorderColor}
                    _hover={{borderColor: "gray.300"}}
                    _focus={{borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce"}}
                />
                <Input
                    placeholder="Filter by NRP"
                    name="nrp"
                    value={filters.nrp}
                    onChange={handleFilterChange}
                    bg={inputBgColor}
                    borderColor={inputBorderColor}
                    _hover={{borderColor: "gray.300"}}
                    _focus={{borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce"}}
                />
            </Box>

            {/* Data Table */}
            <ColumnsTable columnsData={columnsDataColumns} tableData={tableData} onDataUpdate={handleDataUpdate}/>

            {/* Pagination Controls */}
            <Box display="flex" justifyContent="space-between" mt="1.5rem">
                <Button
                    onClick={() => setPageIndex((prev) => Math.max(prev - 1, 0))}
                    disabled={pageIndex === 0}
                >
                    Previous
                </Button>
                <span>
                  Page {pageIndex + 1} of {totalPages}
                </span>
                <Button
                    onClick={() => setPageIndex((prev) => Math.min(prev + 1, totalPages - 1))}
                    disabled={pageIndex + 1 === totalPages}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}
