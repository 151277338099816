import React, {useEffect, useState} from "react";
import {
    Box,
    Button, FormControl, FormLabel,
    Input,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import ColumnsTableRoles from "./components/ColumnsTableRoles";
import {columnsDataRole} from "./variables/columnsDataRole";
import {FaPlus} from "react-icons/fa";
import axios from "axios";

export default function Settings({onDataUpdate}) {
    const [tableData, setTableData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        name: "",
    });

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newData, setNewData] = useState({
        roleName: "",
        roleDescription: ""
    });

    const inputBgColor = useColorModeValue("white", "gray.700");
    const inputBorderColor = useColorModeValue("gray.200", "gray.600");


    // Fetch data from API with pagination and filters
    useEffect(() => {
        // console.log(token)
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];

        fetch(
            process.env.REACT_APP_API_URL + `/v1/role?page=${pageIndex + 1}&size=${pageSize}&roleName=${filters.name}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    // console.log(data.data)
                    setTableData(data.data.content);
                    setTotalPages(data.data.totalPages);
                } else {
                    console.error("Failed to fetch users data");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [pageIndex, pageSize, filters]); // Update fetch when pageIndex, pageSize, or filters change


    const handleDataUpdate = () => {
        // Fetch data terbaru
        // console.log(token)
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        fetch(
            process.env.REACT_APP_API_URL + `/v1/role?page=${pageIndex + 1}&size=${pageSize}&roleName=${filters.name}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setTableData(data.data.content);
                    setTotalPages(data.data.totalPages);
                } else {
                    console.error("Failed to fetch users data");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    // Handle filter changes
    const handleFilterChange = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value});
    };

    const handleAddDataChange = (e) => {
        setNewData({...newData, [e.target.name]: e.target.value});
    };

    const toast = useToast();
    const handleAddData = async () => {
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/role', newData
                , {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            // console.log(response);
            if (response.status === 200) {
                toast({
                    title: 'Role created.',
                    description: 'You data successfully saved.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
                setNewData({
                    nrp: '',
                });
                setIsAddModalOpen(false);
                handleDataUpdate();
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'There was an issue creating your account.',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
    };

    return (
        <Box pt={{base: "130px", md: "80px", xl: "80px"}}>
            <Box display="flex" justifyContent="flex-start" mb="1.5rem">
                <Button
                    colorScheme="brand"
                    size="sm"
                    leftIcon={<FaPlus/>}
                    onClick={() => setIsAddModalOpen(true)}
                >
                    Tambah Data
                </Button>
            </Box>
            Filter Inputs
            <Box display="flex" gap="1rem" mb="1.5rem">
                <Input
                    placeholder="Filter by Name"
                    name="name"
                    value={filters.name}
                    onChange={handleFilterChange}
                    bg={inputBgColor}
                    borderColor={inputBorderColor}
                    _hover={{borderColor: "gray.300"}}
                    _focus={{borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce"}}
                />
            </Box>

            {/* Data Table */}
            <ColumnsTableRoles columnsData={columnsDataRole} tableData={tableData} onDataUpdate={handleDataUpdate}/>

            {/* Pagination Controls */}
            <Box display="flex" justifyContent="space-between" mt="1.5rem">
                <Button
                    onClick={() => setPageIndex((prev) => Math.max(prev - 1, 0))}
                    disabled={pageIndex === 0}
                >
                    Previous
                </Button>
                <span>
                  Page {pageIndex + 1} of {totalPages}
                </span>
                <Button
                    onClick={() => setPageIndex((prev) => Math.min(prev + 1, totalPages - 1))}
                    disabled={pageIndex + 1 === totalPages}
                >
                    Next
                </Button>
            </Box>

            {/* Add Data Modal */}
            <Modal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Add Data Role</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl mb={4} isInvalid={!newData.roleName}>
                            <FormLabel>Role Name</FormLabel>
                            <Input
                                name="roleName"
                                value={newData.roleName}
                                onChange={handleAddDataChange}
                                bg="white"
                            />
                        </FormControl>
                        <FormControl mb={4} isInvalid={!newData.roleDescription}>
                            <FormLabel>Role Description</FormLabel>
                            <Input
                                name="roleDescription"
                                value={newData.roleDescription}
                                onChange={handleAddDataChange}
                                bg="white"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={() => {
                            setIsAddModalOpen(false);
                            setNewData({roleName: "", roleDescription: ""});
                        }}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={() => {
                            setIsAddModalOpen(false);
                            let Swal = require('sweetalert2');
                            Swal.fire({
                                title: 'Are you sure?',
                                text: "Please confirm to save this data",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, save it!'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    handleAddData();
                                } else {
                                    setIsAddModalOpen(true);
                                }
                            })
                        }}
                        isDisabled={!newData.roleName || !newData.roleDescription}>
                            Add Data
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Box>
    );
}
