import React, {useState, useEffect} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    useDisclosure,
    useToast,
    Text,
    FormHelperText
} from '@chakra-ui/react';
import axios from 'axios';

const SignUpModal = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [formData, setFormData] = useState({
        nrp: '',
        email: '',
        password: '',
        name: '',
        companyId: '',
        siteId: ''
    });
    const [companies, setCompanies] = useState([]);
    const [sites, setSites] = useState([]);
    const toast = useToast();

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/v1/company/dropdown');

                if (response.data.success) {
                    setCompanies(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        };

        fetchCompanies().then(r => r);

        const fetchSites = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + '/v1/site/dropdown');

                if (response.data.success) {
                    setSites(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching sites:', error);
            }
        }

        fetchSites().then(r => r);
    }, []);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };


    const handleSignUp = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/register', formData);

            if (response.status === 200) {
                toast({
                    title: 'Account created.',
                    description: 'You have successfully registered.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
                setFormData({
                    nrp: '',
                    email: '',
                    password: '',
                    name: '',
                    companyId: '',
                    siteId: ''
                    
                });
                onClose();
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'There was an issue creating your account.',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
    };

    const handleCancel = () => {
        setFormData({
            nrp: '',
            email: '',
            password: '',
            name: '',
            retypePassword: '',
            companyId: '',
            siteId: ''
        });
        onClose();
    };

    return (
        <>
            <Text color='gray.500' fontWeight='400' fontSize='14px'>
                Not registered yet?
                <Text
                    color='blue.500'
                    as='span'
                    ms='5px'
                    fontWeight='500'
                    cursor='pointer'
                    onClick={onOpen}
                >
                    Create an Account
                </Text>
            </Text>

            <Modal isOpen={isOpen} onClose={handleCancel}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Create an Account</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl isRequired>
                            <FormLabel>NRP</FormLabel>
                            <Input
                                name="nrp"
                                placeholder="Enter your NRP"
                                value={formData.nrp}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl isRequired mt={4}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                name="email"
                                type="email"
                                placeholder="Enter your email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl isRequired mt={4}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                name="password"
                                type="password"
                                placeholder="Enter your password (min. 8 characters)"
                                value={formData.password}
                                onChange={handleInputChange}
                                minLength={8}
                                isInvalid={formData.password.length < 8}
                            />
                            {!formData.password.length < 8 ? (
                                <FormHelperText>
                                    Password must have at least 8 characters.
                                </FormHelperText>
                            ) : null}
                        </FormControl>

                        <FormControl isRequired mt={4}>
                            <FormLabel>Re-type Password</FormLabel>
                            <Input
                                name="retypePassword"
                                type="password"
                                placeholder="Re-type your password"
                                value={formData.retypePassword}
                                onChange={handleInputChange}
                                isInvalid={formData.retypePassword !== formData.password}
                            />
                            {formData.retypePassword !== formData.password ? (
                                <FormHelperText>
                                    Password doesn't match.
                                </FormHelperText>
                            ) : null}
                        </FormControl>

                        <FormControl isRequired mt={4}>
                            <FormLabel>Name</FormLabel>
                            <Input
                                name="name"
                                placeholder="Enter your name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl isRequired mt={4}>
                            <FormLabel>Company</FormLabel>
                            <Select
                                name="companyId"
                                placeholder="Select company"
                                value={formData.company}
                                onChange={handleInputChange}
                            >
                                {companies.map((company) => (
                                    <option key={company.key} value={company.key}>
                                        {company.value}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl isRequired mt={4}>
                            <FormLabel>Site</FormLabel>
                            <Select
                                name="siteId"
                                placeholder="Select site"
                                value={formData.site}
                                onChange={handleInputChange}
                            >
                                {sites.map((site) => (
                                    <option key={site.key} value={site.key}>
                                        {site.value}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleSignUp}>
                            Sign Up
                        </Button>
                        <Button onClick={handleCancel}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default SignUpModal;