import React, {useState} from "react";
import {
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    useToast, Icon,
} from "@chakra-ui/react";
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
} from "@tanstack/react-table";
import axios from "axios";
import {MdOutlineDelete, MdOutlineEdit} from "react-icons/md";

export default function ColumnsTableRoles({columnsData, tableData, onDataUpdate}) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete} = useDisclosure();
    const [selectedRow, setSelectedRow] = useState(null);
    const [editData, setEditData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const textColor = useColorModeValue("gray.700", "gray.200");
    const headerTextColor = useColorModeValue("gray.600", "gray.200");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const headerBgColor = useColorModeValue("gray.50", "gray.700");
    const tableBodyBg = useColorModeValue("white", "gray.800");

    // Setup TanStack Table
    const table = useReactTable({
        data: tableData || [],
        columns: columnsData,
        getCoreRowModel: getCoreRowModel(),
    });

    const handleEditClick = (rowData) => {
        setSelectedRow(rowData);
        setEditData({
            nrp: rowData.nrp || "",
            email: rowData.email || "",
            name: rowData.name || ""
        });
        onOpen();
    };

    const handleDeleteClick = (rowData) => {
        setSelectedRow(rowData);
        onOpenDelete();
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setEditData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const token = localStorage.getItem("token");
    const handleUpdate = async () => {
        setIsLoading(true);
        try {
            // Format request body sesuai kebutuhan
            const requestBody = {
                nrp: editData.nrp,
                email: editData.email,
                name: editData.name
            };

            const response = await axios.patch(
                process.env.REACT_APP_API_URL + `/v1/update`,
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            if (response.status === 200) {
                toast({
                    title: "Update successful",
                    description: "Data has been updated successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                });
                onDataUpdate(); // Panggil callback untuk memberitahu komponen induk bahwa data telah berubah
                onClose();
            }
        } catch (error) {
            toast({
                title: "Update failed",
                description: error.response?.data?.message || "An error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            // Format request body sesuai kebutuhan
            const requestBody = {
                nrp: selectedRow.nrp,
                email: selectedRow.email,
            };

            const response = await axios.delete(process.env.REACT_APP_API_URL + `/v1/users/delete`, {
                data: requestBody,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                toast({
                    title: "Delete successful",
                    description: "Data has been deleted successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                });
                onDataUpdate(); // Panggil callback untuk memberitahu komponen induk bahwa data telah berubah
                onCloseDelete();
            }
        } catch (error) {
            toast({
                title: "Delete failed",
                description: error.response?.data?.message || "An error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Box
                borderRadius="lg"
                overflow="hidden"
                boxShadow="sm"
                mb="24px"
                bg={tableBodyBg}
            >
                <Table variant="simple" bg={tableBodyBg} mb="24px" mt="12px">
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th
                                        key={header.id}
                                        color={headerTextColor}
                                        py={4}
                                        fontWeight="bold"
                                        bg={headerBgColor}
                                        borderColor={borderColor}
                                        borderBottomWidth="2px"
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </Th>
                                ))}
                                <Th
                                    color={headerTextColor}
                                    py={4}
                                    fontWeight="bold"
                                    bg={headerBgColor}
                                    borderColor={borderColor}
                                    borderBottomWidth="2px"
                                >
                                    Actions
                                </Th>
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.length > 0 ? (
                            table.getRowModel().rows.map((row) => (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <Td
                                            key={cell.id}
                                            py={3}
                                            color={textColor}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    ))}
                                    <Td py={3}>
                                        <Button
                                            colorScheme="blue"
                                            size="sm"
                                            onClick={() => handleEditClick(row.original)}
                                            leftIcon={<Icon as={MdOutlineEdit} boxSize={4} color="white"/>}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            colorScheme="red"
                                            size="sm"
                                            ml={2}
                                            onClick={() => handleDeleteClick(row.original)}
                                            leftIcon={<Icon as={MdOutlineDelete} boxSize={4} color="white"/>}
                                        >
                                            Delete
                                        </Button>
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan={columnsData.length + 1}>
                                    <Text textAlign="center" color={textColor} py={4}>
                                        No data available.
                                    </Text>
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Box>

            {/* Edit Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Edit Data</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>NRP</FormLabel>
                            <Input
                                name="nrp"
                                value={editData.nrp}
                                onChange={handleInputChange}
                                bg="white"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                name="email"
                                value={editData.email}
                                onChange={handleInputChange}
                                bg="white"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Name</FormLabel>
                            <Input
                                name="name"
                                value={editData.name}
                                onChange={handleInputChange}
                                bg="white"
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={handleUpdate}
                            isLoading={isLoading}
                        >
                            Save Changes
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* Delete Modal */}
            <Modal isOpen={isOpenDelete} onClose={onCloseDelete}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Delete Data</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Text>
                            Are you sure want to delete data with NRP: {selectedRow?.nrp}?
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onCloseDelete}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="red"
                            onClick={handleDelete}
                            isLoading={isLoading}
                        >
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}