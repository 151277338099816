import React, { useEffect, useState } from "react";
import {
  Button,
  Heading,
  Container,
  VStack,
  Spinner,
  Image,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useDisclosure,
  Text,
  Box,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import axios from 'axios';

const Verify = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idparam = searchParams.get("id");

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isSuccess, setIsSuccess] = useState(false); // NEW: Track successful approval
  const [isReject, setIsReject] = useState(false); // NEW: Track successful approval
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isVisible, setIsVisible] = useState(false); // Deklarasi isVisible dan setIsVisible
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const openModal = () => setIsVisible(true); // Membuka modal
  const onDismiss = () => {
    setIsVisible(false); // Menutup modal
    setVerificationCode(""); // Mengatur ulang input
  };
  const toast = useToast();

  const handleVerify = async () => {
    if (!verificationCode.trim()) {
      toast({
        title: "Error",
        description: "Verification code is required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsVerifying(true);
    try {
      const requestBody = {
        unblacklistToken: verificationCode,
        approved: true,
      };

      // Send the PATCH request
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BLACKLIST}/blacklist/v1/approve-unblacklist`,
        requestBody
      );

      if (response.status === 200) {
        setIsSuccess(true); // NEW: Set success state
        onClose(); // Close the modal
      } else {
        toast({
          title: "Error",
          description: "Something went wrong. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: `Something went wrong: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsVerifying(false);
    }
  };

  const handleReject = async () => {
    if (!verificationCode.trim()) {
      toast({
        title: "Error",
        description: "Verification code is required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsVerifying(true);
    try {
      const requestBody = {
        unblacklistToken: verificationCode,
        approved: false, // Mengatur untuk penolakan
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_API_BLACKLIST}/blacklist/v1/approve-unblacklist`,
        requestBody
      );

      if (response.status === 200) {
        setIsReject(true);
        onDismiss(); 
      } else {
        toast({
          title: "Error",
          description: "Something went wrong. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: `Something went wrong: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsVerifying(false);
    }
  };

  useEffect(() => {
    if (idparam) {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_API_BLACKLIST}/blacklist/v1/unblacklist/check?id=${idparam}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            setData(data.data);
          } else {
            setError("Data tidak ditemukan");
          }
          setLoading(false);
        })
        .catch((error) => {
          setError("Error fetching data: " + error.message);
          setLoading(false);
        });
    }
  }, [idparam]);

  return (
    <Container maxW="container.lg" py={10}>

    {!isSuccess && !isReject ? (
      <VStack
        spacing={6}
        align="center"
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="lg"
      >
        <Heading 
          as="h1" 
          size="xl" 
          color="blue.600" 
          textAlign="center" 
          fontStyle="italic" 
          textTransform="uppercase" 
          letterSpacing="wider" 
          textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
        >
          Approve Un-blacklist
        </Heading>

        {loading && <Spinner color="blue.500" size="xl" />}

        {error && (
          <Text fontSize="lg" color="red.500">
            {error}
          </Text>
        )}

        {!loading && !error && data && (
          <VStack spacing={6} w="full" align="center">
            <Box
              textAlign="center"
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="full"
              maxW="300px"
              margin="0 auto" 
            >
              <Image
                src={data.photoUrl}
                alt="User photo"
                boxSize="250px"
                objectFit="cover"
                borderRadius="full"
                border="2px solid #3182ce"
                boxShadow="2xl"
                transition="transform 0.3s ease, box-shadow 0.3s ease"
                _hover={{
                  transform: "scale(1.1)",
                  boxShadow: "lg",
                }}
              />
            </Box>
            
            <Grid
                  templateColumns="1fr"
                  w="full"
                  alignItems="start"
                >
                    <Grid templateColumns="150px 10px 1fr" gap={2}>
                      <GridItem>
                        <Text fontWeight="bold" fontSize="md">NIK</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">:</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontSize="md" color="gray.700">{data.nik}</Text>
                      </GridItem>
                    </Grid>

                    <Grid templateColumns="150px 10px 1fr" gap={2}>
                      <GridItem>
                        <Text fontWeight="bold" fontSize="md">Company</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">:</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontSize="md" color="gray.700">{data.company}</Text>
                      </GridItem>
                    </Grid>

                    <Grid templateColumns="150px 10px 1fr" gap={2}>
                      <GridItem>
                        <Text fontWeight="bold" fontSize="md">Name</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">:</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontSize="md" color="gray.700">{data.name}</Text>
                      </GridItem>
                    </Grid>

                    <Grid templateColumns="150px 10px 1fr" gap={2}>
                      <GridItem>
                        <Text fontWeight="bold" fontSize="md">Join Date</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">:</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontSize="md" color="gray.700">
                          {new Date(data.joinDate).toLocaleDateString('en-CA')}
                        </Text>
                      </GridItem>
                    </Grid>

                    <Grid templateColumns="150px 10px 1fr" gap={2}>
                      <GridItem>
                        <Text fontWeight="bold" fontSize="md">Location</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">:</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontSize="md" color="gray.700">{data.location}</Text>
                      </GridItem>
                    </Grid>

                    <Grid templateColumns="150px 10px 1fr" gap={2}>
                      <GridItem>
                        <Text fontWeight="bold" fontSize="md">Resign Date</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">:</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontSize="md" color="gray.700">
                          {new Date(data.resignDate).toLocaleDateString('en-CA')}
                        </Text>
                      </GridItem>
                    </Grid>

                    <Grid templateColumns="150px 10px 1fr" gap={2}>
                      <GridItem>
                        <Text fontWeight="bold" fontSize="md">Source</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">:</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontSize="md" color="gray.700">{data.source}</Text>
                      </GridItem>
                    </Grid>
                    
                    <Grid templateColumns="150px 10px 1fr" gap={2}>
                      <GridItem>
                        <Text fontWeight="bold" fontSize="md">Reason Blacklist</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontWeight="bold">:</Text>
                      </GridItem>
                      <GridItem>
                        <Text fontSize="md" color="gray.700">{data.reason}</Text>
                      </GridItem>
                    </Grid>

                    <GridItem colSpan={2} textAlign="center" mt={6}>
                      <Box
                        bg="blue.50"
                        border="1px solid"
                        borderColor="blue.300"
                        borderRadius="md"
                        p={4}
                        shadow="sm"
                      >
                        <Text fontWeight="bold" fontSize="xl" color="blue.800">
                          Reason Un-blacklist
                        </Text>
                        <Text fontSize="lg" color="gray.700" mt={2}>
                          {data.reasonUnblacklist}
                        </Text>
                      </Box>
                    </GridItem>
                </Grid>

            <HStack spacing={6}>
              <Button colorScheme="green" size="lg" onClick={onOpen}>
                Approve
              </Button>
              <Button colorScheme="red" size="lg" onClick={openModal}>
                Reject
              </Button>
            </HStack>
          </VStack>
        )}
      </VStack>
    ) : isSuccess ? (
      <VStack
        spacing={6}
        align="center"
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="lg"
      >
        <Heading 
          as="h1" 
          size="xl" 
          color="green.600" 
          textAlign="center" 
          fontStyle="italic" 
          textTransform="uppercase" 
          letterSpacing="wider" 
          textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
        >
          Approval Successful
        </Heading>
        <Text fontSize="lg" color="gray.700">
          The un-blacklist request has been successfully approved.
        </Text>
      </VStack>
    ) : (
      <VStack
        spacing={6}
        align="center"
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="lg"
      >
        <Heading 
          as="h1" 
          size="xl" 
          color="red.600" 
          textAlign="center" 
          fontStyle="italic" 
          textTransform="uppercase" 
          letterSpacing="wider" 
          textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
        >
          Rejection Successful
        </Heading>
        <Text fontSize="lg" color="gray.700">
          The un-blacklist request has been successfully rejected.
        </Text>
      </VStack>
    )}


    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Verify Approval</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={3} color="gray.600">
            Please enter the verification code sent to your email to proceed with the approval.
          </Text>
          <Input
            placeholder="Enter Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            focusBorderColor="blue.500"
            errorBorderColor="red.500"
            isInvalid={!verificationCode.trim() && isVerifying}
          />
          {isVerifying && (
            <Progress size="xs" isIndeterminate colorScheme="blue" mt={4} />
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isLoading={isVerifying}
            onClick={handleVerify}
          >
            Verify
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

    <Modal isOpen={isVisible} onClose={onDismiss} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reject Unblacklist</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={3} color="gray.600">
              Please enter the verification code to confirm rejection.
            </Text>
            <Input
              placeholder="Enter Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              focusBorderColor="blue.500"
              errorBorderColor="red.500"
              isInvalid={!verificationCode.trim() && isVerifying}
            />
            {isVerifying && (
              <Progress size="xs" isIndeterminate colorScheme="blue" mt={4} />
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              isLoading={isVerifying}
              onClick={handleReject}
            >
              Reject
            </Button>
            <Button variant="ghost" onClick={onDismiss}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Verify;
