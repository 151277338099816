import './assets/css/App.css';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import MasterLayout from './layouts/master';
import {
    // Button,
    ChakraProvider,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react';
import initialTheme from './theme/theme';
import {useState, useEffect} from 'react';
import routes from './routes'; // Pastikan untuk mengimpor rute
import { jwtDecode } from "jwt-decode";

export default function Main() {
    const [currentTheme, setCurrentTheme] = useState(initialTheme);
    const location = useLocation(); // Gunakan useLocation untuk mendapatkan path saat ini
    const {isOpen, onOpen, onClose} = useDisclosure(); // Gunakan useDisclosure untuk kontrol modal
    const isAuthenticated = document.cookie.includes('token') && document.cookie.includes('user');

    const token = document.cookie.split('; ').find(row => row.startsWith('token='))?.split('=')[1];

    // console.log(token);

    if (token) {
        const decodedToken = jwtDecode(token);
        // console.log(decodedToken);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
            document.cookie.split(';').forEach(c => {
                document.cookie = c.trim().split('=')[0] + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/';
            });
            window.location.reload();
        } else {
            fetch(
                `${process.env.REACT_APP_API_URL}/v1/users?page=1&size=10`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            document.cookie.split(";").forEach((c) => {
                                document.cookie = c.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
                            });
                            window.location.reload(); // Untuk mengarahkan kembali ke halaman login
                        }
                        throw new Error("Failed to fetch data");
                    }
                    return response.json();
                })
                .then((data) => {
                    // Handle data if needed
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }

    // Timeout untuk logout otomatis hanya di route admin/*
    useEffect(() => {
        let timeoutId;
        let modalTimeoutId;

        // Fungsi untuk menghapus localStorage dan mengarahkan ke halaman login
        const handleInactivity = () => {
            onOpen();
            modalTimeoutId = setTimeout(() => {
                // localStorage.clear();
                document.cookie.split(";").forEach((c) => {
                    document.cookie = c.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
                });
                window.location.reload(); // Untuk mengarahkan kembali ke halaman login
            }, 10000); // Tunggu 10 detik, lalu logout
        };

        const resetTimer = () => {
            clearTimeout(timeoutId);
            clearTimeout(modalTimeoutId); // Bersihkan modal timeout juga jika ada interaksi
            timeoutId = setTimeout(handleInactivity, 600000); // Inisialisasi timer logout 1 menit
        };

        // Hanya jalankan timeout otomatis pada path admin/*
        if (location.pathname.startsWith('/admin')) {
            window.addEventListener("mousemove", resetTimer);
            window.addEventListener("keydown", resetTimer);
            window.addEventListener("click", resetTimer);
            window.addEventListener("touchstart", resetTimer);

            resetTimer(); // Inisialisasi saat component mount
        }

        // Cleanup untuk membersihkan event listener dan timeout
        return () => {
            clearTimeout(timeoutId);
            clearTimeout(modalTimeoutId);
            window.removeEventListener("mousemove", resetTimer);
            window.removeEventListener("keydown", resetTimer);
            window.removeEventListener("click", resetTimer);
            window.removeEventListener("touchstart", resetTimer);
        };
    }, [location.pathname, onOpen, onClose]);

    return (
        <ChakraProvider theme={currentTheme}>
            {/* Modal untuk konfirmasi logout */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Logout</ModalHeader>
                    <ModalBody>
                        Tidak ada interaksi selama 1 menit. Anda akan logout secara otomatis dalam 10 detik.
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* Routes */}
            <Routes>
                <Route path="auth/*" element={isAuthenticated ? <Navigate to="/admin"/> : <AuthLayout/>}/>
                <Route
                    path="admin/*"
                    element={isAuthenticated ? (
                        <AdminLayout
                            theme={currentTheme}
                            setTheme={setCurrentTheme}
                            routes={routes}
                        />
                    ) : (
                        <Navigate to="/auth"/>
                    )}
                />
                <Route
                    path="master/*"
                    element={isAuthenticated ? (
                        <MasterLayout
                            theme={currentTheme}
                            setTheme={setCurrentTheme}
                            routes={routes}
                        />
                    ) : (
                        <Navigate to="/auth"/>
                    )}
                />
                <Route path="/" element={<Navigate to={isAuthenticated ? "/admin" : "/auth"}/>}/>
            </Routes>
        </ChakraProvider>
    );
}
