// Chakra Imports
import {
    Avatar,
    Button,
    Flex,
    Icon,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useColorMode,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
// Custom Components
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// Assets
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { FaEthereum } from 'react-icons/fa';
import axios from 'axios';
import {SidebarResponsive} from 'components/sidebar/Sidebar';
import routes from 'routes';

export default function HeaderLinks(props) {
    const { secondary } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    const toast = useToast();

    // Separate useDisclosure hooks for each modal
    const {
        isOpen: isResetOpen,
        onOpen: onResetOpen,
        onClose: onResetClose,
    } = useDisclosure();

    const {
        isOpen: isLogoutOpen,
        onOpen: onLogoutOpen,
        onClose: onLogoutClose,
    } = useDisclosure();

    const [isLoading, setIsLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    // Chakra Color Mode
    const navbarIcon = useColorModeValue('gray.400', 'white');
    let menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const ethColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
    const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const ethBox = useColorModeValue('white', 'navy.800');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
    );

    // Get the user name and token from cookies
    const user = document.cookie.split('; ').find(row => row.startsWith('user='))?.split('=')[1].replace(/"/g, '') || '';
    const token = document.cookie.split('; ').find(row => row.startsWith('token='))?.split('=')[1].replace(/"/g, '') || '';

    const handleLogout = () => {
        // Clear cookies
        document.cookie.split(";").forEach((c) => {
            document.cookie = c.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
        });

        const redirectUrl = process.env.REACT_APP_ENV  === 'local'
            ? `${process.env.REACT_APP_API_HOST}:3000/auth`
            : `${process.env.REACT_APP_API_HOST}`;

        // Redirect to the login page
        window.location.href = redirectUrl;
        onLogoutClose();
    };

    // Function to handle password reset
    const handleResetPassword = async () => {
        setIsLoading(true);
        try {
            const requestBody = {
                oldPassword: oldPassword,
                newPassword: newPassword
            };

            const response = await axios.patch(
                process.env.REACT_APP_API_URL + `/v1/update/password`,
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.status === 200) {
                toast({
                    title: "Password Update Successful",
                    description: "Your password has been updated successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                });
                onResetClose(); // Close the modal after success
            }
        } catch (error) {
            toast({
                title: "Password Update Failed",
                description: error.response?.data?.message || "An error occurred while updating the password.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Flex
            w={{ sm: '100%', md: 'auto' }}
            alignItems="center"
            flexDirection="row"
            bg={menuBg}
            flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
            p="10px"
            borderRadius="30px"
            boxShadow={shadow}
        >
            {/* Ethereum Balance Display (optional) */}
            <Flex
                bg={ethBg}
                display={secondary ? 'flex' : 'none'}
                borderRadius="30px"
                ms="auto"
                p="6px"
                align="center"
                me="6px"
            >
                <Flex
                    align="center"
                    justify="center"
                    bg={ethBox}
                    h="29px"
                    w="29px"
                    borderRadius="30px"
                    me="7px"
                >
                    <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
                </Flex>
                <Text
                    w="max-content"
                    color={ethColor}
                    fontSize="sm"
                    fontWeight="700"
                    me="6px"
                >
                    1,924
                    <Text as="span" display={{ base: 'none', md: 'unset' }}>
                        {' '}
                        ETH
                    </Text>
                </Text>
            </Flex>

            <SidebarResponsive routes={routes}/>
            {/* Theme Toggle Button */}
            <Button
                variant="no-hover"
                bg="transparent"
                p="0px"
                minW="unset"
                minH="unset"
                h="30px"
                w="max-content"
                onClick={toggleColorMode}
            >
                <Icon
                    me="10px"
                    h="18px"
                    w="18px"
                    color={navbarIcon}
                    as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
                />
            </Button>

            {/* User Menu */}
            <Menu>
                <MenuButton p="0px">
                    <Avatar
                        _hover={{ cursor: 'pointer' }}
                        color="white"
                        name={user}
                        bg="#11047A"
                        size="sm"
                        w="40px"
                        h="40px"
                    />
                </MenuButton>
                
                <MenuList
                    boxShadow={shadow}
                    p="0px"
                    mt="10px"
                    borderRadius="20px"
                    bg={menuBg}
                    border="none"
                >
                    <Flex w="100%" mb="0px">
                        <Text
                            ps="20px"
                            pt="16px"
                            pb="10px"
                            w="100%"
                            borderBottom="1px solid"
                            borderColor={borderColor}
                            fontSize="sm"
                            fontWeight="700"
                            color={textColor}
                        >
                            👋&nbsp; Hey, {user}
                        </Text>
                    </Flex>

                    <Flex flexDirection="column" p="10px">
                        {window.location.pathname !== '/admin' && (
                            <MenuItem
                                as="a"
                                href={`${process.env.REACT_APP_API_HOST}:3000/admin`}
                                _hover={{ bg: 'gray.100' }}
                                _focus={{ bg: 'gray.200' }}
                                borderRadius="8px"
                                px="14px"
                                py="10px"
                                transition="background-color 0.2s"
                            >
                                <Text fontSize="sm" fontWeight="600" color="blue.500">
                                    Home Page Application
                                </Text>
                            </MenuItem>
                        )}

                        <MenuItem
                            onClick={onResetOpen}
                            _hover={{ bg: 'gray.100' }}
                            _focus={{ bg: 'gray.200' }}
                            color="blue.400"
                            borderRadius="8px"
                            px="14px"
                        >
                            <Text fontSize="sm">Reset Password</Text>
                        </MenuItem>

                        <MenuItem
                            onClick={onLogoutOpen}
                            _hover={{ bg: 'gray.100' }}
                            _focus={{ bg: 'gray.200' }}
                            color="red.400"
                            borderRadius="8px"
                            px="14px"
                        >
                            <Text fontSize="sm">Log out</Text>
                        </MenuItem>

                    </Flex>

                    {/* Modal for Reset Password */}
                    <Modal isOpen={isResetOpen} onClose={onResetClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Reset Password</ModalHeader>
                            <ModalBody>
                                <Text mb="4px">Old Password</Text>
                                <Input
                                    type="password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    placeholder="Enter old password"
                                    mb="4"
                                />
                                <Text mb="4px">New Password</Text>
                                <Input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Enter new password"
                                    mb="4"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={onResetClose} mr={3}>
                                    Cancel
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    onClick={handleResetPassword}
                                    isLoading={isLoading}
                                >
                                    Reset Password
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* Modal for Logout Confirmation */}
                    <Modal isOpen={isLogoutOpen} onClose={onLogoutClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Confirm Logout</ModalHeader>
                            <ModalBody>
                                <Text>Are you sure you want to log out?</Text>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={onLogoutClose} mr={3}>
                                    No
                                </Button>
                                <Button colorScheme="red" onClick={handleLogout}>
                                    Yes
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </MenuList>
            </Menu>
        </Flex>
    );
}

HeaderLinks.propTypes = {
    secondary: PropTypes.bool,
};
