import React, {useEffect, useState} from "react";
import {
    Box,
    Button, FormControl, FormLabel,
    Input,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import ColumnsTableRoles from "./components/ColumnsTableRoles";
import {columnsDataRole} from "./variables/columnsDataRole";
import {FaPlus} from "react-icons/fa";
import axios from "axios";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function Settings({onDataUpdate}) {
    const [tableData, setTableData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        name: "",
    });
    const [roles, setRoles] = useState([]); // untuk menyimpan data role
    const [permissions, setPermissions] = useState([]); // untuk menyimpan data permission

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newData, setNewData] = useState({
        role: "",
        permission: []
    });


    const inputBgColor = useColorModeValue("white", "gray.700");
    const inputBorderColor = useColorModeValue("gray.200", "gray.600");


    // Fetch data from API with pagination and filters
    useEffect(() => {
        // console.log(token)
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];

        fetch(
            process.env.REACT_APP_API_URL + `/v1/role?page=${pageIndex + 1}&size=${pageSize}&roleName=${filters.name}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    // console.log(data.data)
                    const tableData = data.data.content.map((role) => {
                        const rolePermissions = role.rolePermissions.map((permission) => {
                            return `${permission.permission.description}`;
                        }).join(' | ');
                        return {
                            ...role,
                            description: rolePermissions,
                        };
                    });
                    setTableData(tableData);
                    setTotalPages(data.data.totalPages);
                } else {
                    console.error("Failed to fetch users data");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [pageIndex, pageSize, filters]); // Update fetch when pageIndex, pageSize, or filters change

    useEffect(() => {
        // fetch data role dari API
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        fetch(process.env.REACT_APP_API_URL + `/v1/role?page=1&size=9999`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => setRoles(data.data.content));

        // fetch data permission dari API
        fetch(process.env.REACT_APP_API_URL + `/v1/permission?page=1&size=9999`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => setPermissions(data.data.content));
    }, []);


    const handleDataUpdate = () => {
        // Fetch data terbaru
        // console.log(token)
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        fetch(
            process.env.REACT_APP_API_URL + `/v1/role?page=${pageIndex + 1}&size=${pageSize}&roleName=${filters.name}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    // console.log(data.data)
                    const tableData = data.data.content.map((role) => {
                        const rolePermissions = role.rolePermissions.map((permission) => {
                            return `${permission.permission.description}`;
                        }).join(' | ');
                        return {
                            ...role,
                            description: rolePermissions,
                        };
                    });
                    setTableData(tableData);
                    setTotalPages(data.data.totalPages);
                } else {
                    console.error("Failed to fetch users data");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    // Handle filter changes
    const handleFilterChange = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value});
    };

    // Buat handler khusus untuk role (single select)
    const handleRoleChange = async (selectedOption) => {

        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + `/v1/role/permissions?roleId=${selectedOption.value}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                const tableData = response.data.data;
                const permissions = tableData.map(item => item.permission.id);

                setNewData({
                    ...newData,
                    role: selectedOption ? selectedOption.value : "",
                    permission: permissions,
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }

        console.log('Selected Role:', selectedOption);
    };

// Buat handler khusus untuk permission (multi select)
    const handlePermissionChange = (selectedOptions) => {
        setNewData({
            ...newData,
            permission: selectedOptions ? selectedOptions.map(option => option.value) : []
        });
    };


    const toast = useToast();
    const handleAddData = async () => {
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        try {
            // Get existing permissions for the role
            const oldData = await axios.get(process.env.REACT_APP_API_URL + '/v1/role/permissions?roleId=' + newData.role);
            const existingPermissionIds = oldData.data.data.map(item => item.permission.id);
            const permissionsToDelete = existingPermissionIds.filter(id => !newData.permission.includes(id));

            // Delete existing permissions that are not in new selection
            for (const permissionId of permissionsToDelete) {
                await axios.delete(process.env.REACT_APP_API_URL + '/v1/role/permission', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        roleId: newData.role,
                        permissionId: permissionId
                    }
                });
            }

            const transformedData = newData.permission.map(permissionId => ({
                permissionId: permissionId,
                roleId: newData.role
            }));

            const response = await axios.post(
                process.env.REACT_APP_API_URL + '/v1/role/permissions',
                transformedData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.status === 200) {
                toast({
                    title: 'Role Permission created.',
                    description: 'You data successfully saved. Please refresh page.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
                setNewData({role: "", permission: []});
                setIsAddModalOpen(false);
                handleDataUpdate();
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'There was an issue updating the permissions.',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
    };

    return (
        <Box pt={{base: "130px", md: "80px", xl: "80px"}}>
            {/*Add Button*/}
            <Box display="flex" justifyContent="flex-start" mb="1.5rem">
                <Button
                    colorScheme="brand"
                    size="sm"
                    leftIcon={<FaPlus/>}
                    onClick={() => setIsAddModalOpen(true)}
                >
                    Tambah Data
                </Button>
            </Box>
            Filter Inputs
            <Box display="flex" gap="1rem" mb="1.5rem">
                <Input
                    placeholder="Filter by Name"
                    name="name"
                    value={filters.name}
                    onChange={handleFilterChange}
                    bg={inputBgColor}
                    borderColor={inputBorderColor}
                    _hover={{borderColor: "gray.300"}}
                    _focus={{borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce"}}
                />
            </Box>

            {/* Data Table */}
            <ColumnsTableRoles columnsData={columnsDataRole} tableData={tableData} onDataUpdate={handleDataUpdate}/>

            {/* Pagination Controls */}
            <Box display="flex" justifyContent="space-between" mt="1.5rem">
                <Button
                    onClick={() => setPageIndex((prev) => Math.max(prev - 1, 0))}
                    disabled={pageIndex === 0}
                >
                    Previous
                </Button>
                <span>
                  Page {pageIndex + 1} of {totalPages}
                </span>
                <Button
                    onClick={() => setPageIndex((prev) => Math.min(prev + 1, totalPages - 1))}
                    disabled={pageIndex + 1 === totalPages}
                >
                    Next
                </Button>
            </Box>

            {/* Add Data Modal */}
            <Modal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Add Data Permission</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>Role</FormLabel>
                            <Select
                                name="role"
                                value={roles.map((role) => ({
                                    value: role.id,
                                    label: role.roleName,
                                })).find(option => option.value === newData.role)}
                                onChange={handleRoleChange}
                                options={roles.map((role) => ({
                                    value: role.id,
                                    label: role.roleName,
                                }))}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        backgroundColor: 'white',
                                    })
                                }}
                                components={animatedComponents}
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Permission</FormLabel>
                            <Select
                                name="permission"
                                isMulti={true}
                                value={permissions
                                    .filter(permission => newData.permission.includes(permission.id))
                                    .map(permission => ({
                                        value: permission.id,
                                        label: `${permission.description}`
                                    }))}
                                onChange={handlePermissionChange}
                                options={permissions.map((permission) => ({
                                    value: permission.id,
                                    label: `${permission.description}`
                                }))}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        backgroundColor: 'white',
                                    })
                                }}
                                components={animatedComponents}
                                isSearchable={true}
                                closeMenuOnSelect={false}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={() => {
                            setIsAddModalOpen(false);
                            setNewData({role: "", permission: []});
                        }}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue"
                                onClick={() => {
                                    setIsAddModalOpen(false);
                                    let Swal = require('sweetalert2');
                                    Swal.fire({
                                        title: 'Are you sure?',
                                        text: "Please confirm to save this data",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes, save it!'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            handleAddData();
                                        } else {
                                            setIsAddModalOpen(true);
                                        }
                                    });
                                }}
                                isDisabled={!newData.role || !newData.permission}>
                            Add Data
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}
