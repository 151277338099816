import React, {useEffect, useState} from "react";
import {
    Box,
    Button, FormControl, FormLabel,
    Input,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useColorModeValue,
    useToast,
    Select
} from "@chakra-ui/react";
import ColumnsTablePermission from "./components/ColumnsTablePermission";
import {columnsDataPermission} from "./variables/columnsDataPermission";
import {FaPlus} from "react-icons/fa";
import axios from "axios";

export default function Settings({onDataUpdate}) {
    const [tableData, setTableData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        serviceName: "",
        menuName: "",
        subMenuName: "",
    });

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newData, setNewData] = useState({
        service: "",
        menu: "",
        subMenu: "",
        action: "",
        description: ""
    });

    const inputBgColor = useColorModeValue("white", "gray.700");
    const inputBorderColor = useColorModeValue("gray.200", "gray.600");


    // Fetch data from API with pagination and filters
    useEffect(() => {
        // console.log(token)
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];

        fetch(
            process.env.REACT_APP_API_URL + `/v1/permission?page=${pageIndex + 1}&size=${pageSize}&serviceName=${filters.serviceName}&menuName=${filters.menuName}&subMenuName=${filters.subMenuName}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    // console.log(data.data)
                    setTableData(data.data.content);
                    setTotalPages(data.data.totalPages);
                } else {
                    console.error("Failed to fetch users data");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [pageIndex, pageSize, filters]); // Update fetch when pageIndex, pageSize, or filters change


    const handleDataUpdate = () => {
        // Fetch data terbaru
        // console.log(token)
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        fetch(
            process.env.REACT_APP_API_URL + `/v1/permission?page=${pageIndex + 1}&size=${pageSize}&serviceName=${filters.serviceName}&menuName=${filters.menuName}&subMenuName=${filters.subMenuName}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setTableData(data.data.content);
                    setTotalPages(data.data.totalPages);
                } else {
                    console.error("Failed to fetch users data");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    // Handle filter changes
    const handleFilterChange = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value});
    };

    const handleAddDataChange = (e) => {
        setNewData({...newData, [e.target.name]: e.target.value});
    };

    const toast = useToast();
    const handleAddData = async () => {
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/permission', newData
                , {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            // console.log(response);
            if (response.status === 200) {
                toast({
                    title: 'Permission created.',
                    description: 'You data successfully saved.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
                setNewData({
                    nrp: '',
                });
                setIsAddModalOpen(false);
                handleDataUpdate();
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: 'There was an issue creating your account.',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
    };

    return (
        <Box pt={{base: "130px", md: "80px", xl: "80px"}}>
            <Box display="flex" justifyContent="flex-start" mb="1.5rem">
                <Button
                    colorScheme="brand"
                    size="sm"
                    leftIcon={<FaPlus/>}
                    onClick={() => setIsAddModalOpen(true)}
                >
                    Tambah Data
                </Button>
            </Box>
            Filter Inputs
            <Box display="flex" gap="1rem" mb="1.5rem">
                <Input
                    placeholder="Filter by Service Name"
                    name="serviceName"
                    value={filters.serviceName}
                    onChange={handleFilterChange}
                    bg={inputBgColor}
                    borderColor={inputBorderColor}
                    _hover={{borderColor: "gray.300"}}
                    _focus={{borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce"}}
                />
                <Input
                    placeholder="Filter by Menu Name"
                    name="menuName"
                    value={filters.menuName}
                    onChange={handleFilterChange}
                    bg={inputBgColor}
                    borderColor={inputBorderColor}
                    _hover={{borderColor: "gray.300"}}
                    _focus={{borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce"}}
                />
                <Input
                    placeholder="Filter by Sub Menu Name"
                    name="subMenuName"
                    value={filters.subMenuName}
                    onChange={handleFilterChange}
                    bg={inputBgColor}
                    borderColor={inputBorderColor}
                    _hover={{borderColor: "gray.300"}}
                    _focus={{borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce"}}
                />
            </Box>

            {/* Data Table */}
            <ColumnsTablePermission columnsData={columnsDataPermission} tableData={tableData}
                                    onDataUpdate={handleDataUpdate}/>

            {/* Pagination Controls */}
            <Box display="flex" justifyContent="space-between" mt="1.5rem">
                <Button
                    onClick={() => setPageIndex((prev) => Math.max(prev - 1, 0))}
                    disabled={pageIndex === 0}
                >
                    Previous
                </Button>
                <span>
                  Page {pageIndex + 1} of {totalPages}
                </span>
                <Button
                    onClick={() => setPageIndex((prev) => Math.min(prev + 1, totalPages - 1))}
                    disabled={pageIndex + 1 === totalPages}
                >
                    Next
                </Button>
            </Box>

            {/* Add Data Modal */}
            <Modal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Add Data Permission</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl mb={4} isInvalid={!newData.service}>
                            <FormLabel>Name Service</FormLabel>
                            <Input
                                name="service"
                                value={newData.service}
                                onChange={handleAddDataChange}
                                bg="white"
                            />
                        </FormControl>
                        <FormControl mb={4} isInvalid={!newData.menu}>
                            <FormLabel>Menu</FormLabel>
                            <Input
                                name="menu"
                                value={newData.menu}
                                onChange={handleAddDataChange}
                                bg="white"
                            />
                        </FormControl>
                        <FormControl mb={4} isInvalid={!newData.subMenu}>
                            <FormLabel>subMenu</FormLabel>
                            <Input
                                name="subMenu"
                                value={newData.subMenu}
                                onChange={handleAddDataChange}
                                bg="white"
                            />
                        </FormControl>
                        <FormControl mb={4} isInvalid={!newData.action}>
                            <FormLabel>Action</FormLabel>
                            <Select
                                name="action"
                                value={newData.action}
                                onChange={handleAddDataChange}
                                bg="white"
                            >
                                <option value="">Select Action</option>
                                <option value="view">View</option>
                                <option value="add">Add</option>
                                <option value="edit">Edit</option>
                                <option value="delete">Delete</option>
                            </Select>
                        </FormControl>
                        <FormControl mb={4} isInvalid={!newData.description}>
                            <FormLabel>Description</FormLabel>
                            <Input
                                name="description"
                                value={newData.description}
                                onChange={handleAddDataChange}
                                bg="white"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={() => {
                            setIsAddModalOpen(false);
                            setNewData({service: "", menu: "", subMenu: "", action: "", description: ""});
                        }}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue"
                                onClick={() => {
                                    setIsAddModalOpen(false);
                                    let Swal = require('sweetalert2');
                                    Swal.fire({
                                        title: 'Are you sure?',
                                        text: "Please confirm to save this data",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes, save it!'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            handleAddData();
                                        } else {
                                            setIsAddModalOpen(true);
                                        }
                                    })
                                }}
                                isDisabled={!newData.service || !newData.menu || !newData.subMenu || !newData.action || !newData.description}>
                            Add Data
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Box>
    );
}
