export const columnsDataManageRole = [
  {
    accessorKey: "userName",
    header: "Name User",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "userEmail",
    header: "Email User",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "roleName",
    header: "Name Role",
    cell: (info) => info.getValue(),
  },
];
