import React, {useEffect, useState} from "react";
import {
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    // useDisclosure,
    useToast, Icon,
} from "@chakra-ui/react";
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
} from "@tanstack/react-table";
import axios from "axios";
import {MdOutlineEdit} from "react-icons/md";
import Select from "react-select";
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function ColumnsTableManageRoles({columnsData, tableData, onDataUpdate}) {
    // const {isOpen, onOpen, onClose} = useDisclosure();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [role, setRole] = useState([]);

    // const {isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete} = useDisclosure();
    const [selectedRow, setSelectedRow] = useState(null);
    const [editData, setEditData] = useState({
        user: "",
        role: []
    });
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const textColor = useColorModeValue("gray.700", "gray.200");
    const headerTextColor = useColorModeValue("gray.600", "gray.200");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const headerBgColor = useColorModeValue("gray.50", "gray.700");
    const tableBodyBg = useColorModeValue("white", "gray.800");

    // Setup TanStack Table
    const table = useReactTable({
        data: tableData || [],
        columns: columnsData,
        getCoreRowModel: getCoreRowModel(),
    });

    useEffect(() => {

        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        // fetch data role dari API
        fetch(process.env.REACT_APP_API_URL + `/v1/role?page=1&size=9999`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => setRole(data.data.content));
    }, []);

    const handleRoleChange = (selectedOptions) => {
        setEditData({
            ...editData,
            role: selectedOptions ? selectedOptions.map(option => option.value) : []
        });
    };

    const handleEditClick = async (rowData) => {
        setSelectedRow(rowData);
        // console.log(rowData);

        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + `/v1/user-roles?userId=${rowData.id}`);
            if (response.status === 200) {
                const tableData = response.data.data;
                const roles = tableData.map(item => item.role.id);
                // console.log(roles);
                setEditData({
                    user: rowData.name || "",
                    role: roles
                });
                setIsEditModalOpen(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // const handleDeleteClick = (rowData) => {
    //     setSelectedRow(rowData);
    //     onOpenDelete();
    // };


    const token = localStorage.getItem("token");
    const handleUpdate = async () => {
        const token = document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1];
        try {
            const transformedData = editData.role.map(roleId => ({
                roleId: roleId,
                userId: selectedRow.id
            }));

            const oldData = await axios.get(process.env.REACT_APP_API_URL + '/v1/user-roles?userId=' + selectedRow.id);
            const existingRoleIds = oldData.data.data.map(item => item.role.id);
            const rolesToDelete = existingRoleIds.filter(id => !editData.role.includes(id));

            // Delete existing roles that are not in new selection
            for (const roleId of rolesToDelete) {
                await axios.delete(process.env.REACT_APP_API_URL + '/v1/user-role', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        userId: selectedRow.id,
                        roleId: roleId
                    }
                });
            }

            const response = await axios.post(
                process.env.REACT_APP_API_URL + '/v1/user-roles',
                transformedData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.status === 200) {
                toast({
                    title: "Update successful",
                    description: "Data has been updated successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                onDataUpdate();
                setIsEditModalOpen(false);
            }
        } catch (error) {
            toast({
                title: "Update failed",
                description: error.response?.data?.message || "An error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
        } finally {
            setIsLoading(false);
        }
    };

    // const handleDelete = async () => {
    //     setIsLoading(true);
    //     try {
    //         // Format request body sesuai kebutuhan
    //         const requestBody = {
    //             nrp: selectedRow.nrp,
    //             email: selectedRow.email,
    //         };
    //
    //         const response = await axios.delete(process.env.REACT_APP_API_URL + `/v1/users/delete`, {
    //             data: requestBody,
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         });
    //
    //         if (response.status === 200) {
    //             toast({
    //                 title: "Delete successful",
    //                 description: "Data has been deleted successfully",
    //                 status: "success",
    //                 duration: 3000,
    //                 isClosable: true,
    //                 position: "top-right",
    //             });
    //             onDataUpdate(); // Panggil callback untuk memberitahu komponen induk bahwa data telah berubah
    //             // onCloseDelete();
    //         }
    //     } catch (error) {
    //         toast({
    //             title: "Delete failed",
    //             description: error.response?.data?.message || "An error occurred",
    //             status: "error",
    //             duration: 3000,
    //             isClosable: true,
    //             position: "top-right",
    //         });
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    return (
        <>
            <Box
                borderRadius="lg"
                overflow="hidden"
                boxShadow="sm"
                mb="24px"
                bg={tableBodyBg}
            >
                <Table variant="simple" bg={tableBodyBg} mb="24px" mt="12px">
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th
                                        key={header.id}
                                        color={headerTextColor}
                                        py={4}
                                        fontWeight="bold"
                                        bg={headerBgColor}
                                        borderColor={borderColor}
                                        borderBottomWidth="2px"
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </Th>
                                ))}
                                <Th
                                    color={headerTextColor}
                                    py={4}
                                    fontWeight="bold"
                                    bg={headerBgColor}
                                    borderColor={borderColor}
                                    borderBottomWidth="2px"
                                >
                                    Actions
                                </Th>
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.length > 0 ? (
                            table.getRowModel().rows.map((row) => (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <Td
                                            key={cell.id}
                                            py={3}
                                            color={textColor}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    ))}
                                    <Td py={3}>
                                        <Button
                                            colorScheme="blue"
                                            size="sm"
                                            onClick={() => handleEditClick(row.original)}
                                            leftIcon={<Icon as={MdOutlineEdit} boxSize={4} color="white"/>}
                                        >
                                            Edit
                                        </Button>
                                        {/*<Button*/}
                                        {/*    colorScheme="red"*/}
                                        {/*    size="sm"*/}
                                        {/*    ml={2}*/}
                                        {/*    onClick={() => handleDeleteClick(row.original)}*/}
                                        {/*    leftIcon={<Icon as={MdOutlineDelete} boxSize={4} color="white"/>}*/}
                                        {/*>*/}
                                        {/*    Delete*/}
                                        {/*</Button>*/}
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan={columnsData.length + 1}>
                                    <Text textAlign="center" color={textColor} py={4}>
                                        No data available.
                                    </Text>
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Box>

            {/* Edit Modal */}
            <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Edit Data User Role</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>User</FormLabel>
                            <Input
                                name="user"
                                value={editData.user}
                                readOnly
                            ></Input>
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Role</FormLabel>
                            <Select
                                name="role"
                                isMulti={true}
                                value={role
                                    .filter(role => Array.isArray(editData.role) && editData.role.includes(role.id))
                                    .map(role => ({
                                        value: role.id,
                                        label: role.roleName
                                    }))}
                                onChange={handleRoleChange}
                                options={role.map((role) => ({
                                    value: role.id,
                                    label: role.roleName
                                }))}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        backgroundColor: 'white',
                                    }),
                                    multiValue: (provided) => ({
                                        ...provided,
                                        maxWidth: '100%'
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        whiteSpace: 'normal',
                                        height: 'auto',
                                        maxWidth: '100%',
                                        overflow: 'visible'
                                    }),
                                    multiValueLabel: (provided) => ({
                                        ...provided,
                                        whiteSpace: 'normal',
                                        overflow: 'visible'
                                    })
                                }}
                                components={animatedComponents}
                                isSearchable={true}
                                closeMenuOnSelect={false}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={() => {
                            setIsEditModalOpen(false);
                            setEditData({user: "", role: []});
                        }}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={() => {
                            setIsEditModalOpen(false);
                            let Swal = require('sweetalert2');
                            Swal.fire({
                                title: 'Are you sure?',
                                text: "You want to edit this data?",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, edit it!'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    handleUpdate();
                                } else {
                                    setIsEditModalOpen(true);
                                }
                            })
                        }}
                                isDisabled={!editData.role || !editData.user}>
                            Edit Data
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* Delete Modal */}
            {/*<Modal isOpen={isOpenDelete} onClose={onCloseDelete}>*/}
            {/*    <ModalOverlay/>*/}
            {/*    <ModalContent>*/}
            {/*        <ModalHeader>Delete Data</ModalHeader>*/}
            {/*        <ModalCloseButton/>*/}
            {/*        <ModalBody>*/}
            {/*            <Text>*/}
            {/*                Are you sure want to delete data with NRP: {selectedRow?.nrp}?*/}
            {/*            </Text>*/}
            {/*        </ModalBody>*/}

            {/*        <ModalFooter>*/}
            {/*            <Button variant="ghost" mr={3} onClick={onCloseDelete}>*/}
            {/*                Cancel*/}
            {/*            </Button>*/}
            {/*            <Button*/}
            {/*                colorScheme="red"*/}
            {/*                onClick={handleDelete}*/}
            {/*                isLoading={isLoading}*/}
            {/*            >*/}
            {/*                Delete*/}
            {/*            </Button>*/}
            {/*        </ModalFooter>*/}
            {/*    </ModalContent>*/}
            {/*</Modal>*/}
        </>
    );
}