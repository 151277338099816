import React from 'react';

import {Icon} from '@chakra-ui/react';
import {
    MdHome,
    // MdBarChart,
    // MdPerson,
    // MdHome,
    MdLock,
    // MdOutlineShoppingCart,
    MdPerson,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/master/dashboard';
import User from "views/master/user";
import Role from "views/master/role";
import Permission from "views/master/permission";
import AccountManagement from "views/master/account-management";
import UserRole from "views/master/user-roles-management";


// Auth Imports
import SignInCentered from 'views/auth/signIn';
import VerifyEmail from 'views/auth/verifyEmail';
import Verify from 'views/blacklist/verify/unblacklist';


const routes = [
    {
        name: 'Main Dashboard',
        layout: '/master',
        path: '/dashboard',
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit"/>,
        component: <MainDashboard/>,
    },
    {
        name: 'Master Data',
        layout: '/master',
        path: '/user',
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit"/>,
        children: [
            {
                name: 'Master User',
                layout: '/master',
                path: '/user/index',
                component: <User/>,
            },
            {
                name: 'Master Role',
                layout: '/master',
                path: '/user/role',
                component: <Role/>,
            },
            {
                name: 'Master Permission',
                layout: '/master',
                path: '/user/permission',
                component: <Permission/>
            },
            {
                name: 'Master User Role',
                layout: '/master',
                path: '/user/user-roles-management',
                component: <UserRole/>
            },
            {
                name: 'Master Role Permission',
                layout: '/master',
                path: '/user/account-management',
                component: <AccountManagement/>
            },
        ],
    },
    {
        name: 'Sign In',
        layout: '/auth',
        path: '/sign-in',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit"/>,
        component: <SignInCentered/>,
        showInSidebar: false,
    },
    {
        name: 'Verify Email',
        layout: '/auth',
        path: '/verify-email',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit"/>,
        component: <VerifyEmail/>,
        showInSidebar: false,
    },
    {
        name: 'Verify',
        layout: '/auth',
        path: '/verify/unblacklist',
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit"/>,
        component: <Verify/>,
        showInSidebar: false,
    }
];

export default routes;
