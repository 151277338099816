export const columnsDataPermission = [
    {
        accessorKey: "service",
        header: "Name Service",
        cell: (info) => info.getValue(),
    },
    {
        accessorKey: "menu",
        header: "Name Menu",
        cell: (info) => info.getValue(),
    },
    {
        accessorKey: "subMenu",
        header: "Name Sub Menu",
        cell: (info) => info.getValue(),
    },
    {
        accessorKey: "action",
        header: "Action",
        cell: (info) => info.getValue(),
    },
    {
        accessorKey: "description",
        header: "Description",
        cell: (info) => info.getValue(),
    },
];
